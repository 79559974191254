import { defineStore } from 'pinia'
import { useContentStore } from '~/composables/stores/useContentStore.js'

export const formsEndpoint = '/notion/workspaces/{workspaceId}/forms'
export const singleFormEndpoint = '/notion/forms/{slug}'

export const useFormsStore = defineStore('forms', () => {

  const contentStore = useContentStore('slug')
  const allLoaded = ref(false)
  const currentPage = ref(1)

  const loadAllRequest = ref(null)

  /*
    * Load all forms from the API with pagination, ensure there's only 1 request at a time
   */
  const loadAll = (workspaceId) => {
    if (loadAllRequest.value) {
      return loadAllRequest.value
    }
    if (!workspaceId) {
      return
    }
    contentStore.startLoading()
    // eslint-disable-next-line
    loadAllRequest.value = new Promise(async (resolve, reject) => {
      try {
        const response = await noteFormsFetch(formsEndpoint.replace('{workspaceId}', workspaceId), { query: { page: currentPage.value } })
        if (currentPage.value === 1) {
          contentStore.resetState() // Reset the state if it's the first page
          contentStore.save(response.data)
        } else {
          contentStore.save(response.data)
        }
        if (currentPage.value < response.meta.last_page) {
          currentPage.value++
          loadAllRequest.value = null
          loadAll(workspaceId)
        } else {
          allLoaded.value = true
          contentStore.stopLoading()
          loadAllRequest.value = null
          currentPage.value = 1
        }
        resolve()
      } catch (err) {
        contentStore.stopLoading()
        currentPage.value = 1
        loadAllRequest.value = null
        reject(err)
      }
    })

    return loadAllRequest.value
  }

  const load = (workspaceId, slug) => {
    if (!slug) {
      return
    }
    contentStore.startLoading()
    return noteFormsFetch(formsEndpoint.replace('{workspaceId}', workspaceId) + '/' + slug)
      .finally(() => {
        contentStore.stopLoading()
      })
  }

  const loadForm = (slug) => {
    contentStore.startLoading()
    return noteFormsFetch(singleFormEndpoint.replace('{slug}', slug))
      .then(response => {
        contentStore.save(response)
      })
      .finally(() => {
        contentStore.stopLoading()
      })
  }

  /**
   * Load a form from the public API
   */
  const publicLoad = (slug) => {
    contentStore.startLoading()
    return useNoteFormsApi('/forms/' + slug)
  }

  const publicFetch = (slug) => {
    contentStore.startLoading()
    return noteFormsFetch('/forms/' + slug)
  }

  const allTags = computed(() => {
    let tags = []
    contentStore.getAll.value.forEach((form) => {
      if (form.tags && form.tags.length) {
        if (typeof form.tags === 'string' || form.tags instanceof String) {
          tags = tags.concat(form.tags.split(','))
        } else if (Array.isArray(form.tags)) {
          tags = tags.concat(form.tags)
        }
      }
    })
    return [...new Set(tags)].sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
  })

  return {
    ...contentStore,
    allLoaded,
    allTags,
    publicLoad,
    publicFetch,
    loadAll,
    load,
    loadForm
  }
})
